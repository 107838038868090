import React, { useEffect, useState } from "react"
import VideosList from "./VideosList/VideosList"
import { VideosGrid } from "../../queries/common_use_query"

import {GetVideos} from "../../queries/api_links"
import {
  Container,
  Row,
  Col
} from "react-bootstrap"
import "./Videos.scss"

// styles
// markup
const Videos = ( props ) => {

  console.log("Videos list", props)

  const [video_items, setVideoItems] = useState([]);
  const [video_list, setVideoList] = useState(true);
  const [filterCat, setFilterCat] = useState('');
  const [include_videos, setIncludeVideos] = useState([]);
  // console.log("@@@@", data+"---"+data);

  const filterCateogy = (playlist_id, include_video) =>{
    //console.log("category", category);
    setFilterCat(playlist_id);
    setVideoList(true);
    setVideoItems([]);
    setIncludeVideos(include_video);

    const youtube_videos = GetVideos(playlist_id).then(res =>{
      //console.log("youtube_videos", res);
      if(res.status === 200){
        setVideoItems(res.data.items);
        if(res.data.nextPageToken){
          nextPageVideos(playlist_id, res.data.nextPageToken, res.data.items);
        }       
      } else{
        setVideoItems([]);
        setVideoList(false)
      }
    });
  }

  useEffect(()=>{
    var playlist_id = props.Modules.Playlist_Videos[0];
    setFilterCat(playlist_id.Playlist_ID);
    setIncludeVideos(props.Modules.Playlist_Videos[0].Include_Video_ID);

    const youtube_videos = GetVideos(playlist_id.Playlist_ID).then(res =>{
      //console.log("youtube_videos", res);
      if(res.status === 200){
        setVideoItems(res.data.items);

        if(res.data.nextPageToken){
          nextPageVideos(playlist_id.Playlist_ID, res.data.nextPageToken, res.data.items);
        }

        
      } else{
        setVideoItems([]);
        setVideoList(false)
      }
    });
  },[])

  const nextPageVideos = (playlist_id, nextPageToken, prevItems)=>{
    //var playlist_id = props.Modules.Playlist_Item[0];

    //console.log("playlist_id", playlist_id);

    const youtube_videos = GetVideos(playlist_id, nextPageToken).then(res =>{
      console.log("youtube_videos", res);
      var video_array = [];
      prevItems.length > 0 && prevItems.map((item)=>{
        video_array.push(item);
      })
      if(res.status === 200){
        res.data.items.length > 0 && res.data.items.map((item)=>{
          video_array.push(item);
          
        })
      } else{
        setVideoItems([]);
        setVideoList(false)
      }

      setVideoItems(video_array);
      
    });
  }


  //console.log("include_videos", include_videos);


  return (
    <React.Fragment>
      <div className="filter-block insights video_filter">
        <Container>
          <Row>
            <Col lg="12">
              <div className="filter-block-group video_filt_blk">

                  <div className="filter-link">
                    {
                      props.Modules.Playlist_Videos && props.Modules.Playlist_Videos.length > 0 && props.Modules.Playlist_Videos.map((item, index) => {
                        return(
                          <span className={`filter-link-btn ${item.Playlist_ID === filterCat && 'filter-link-btn--active'}`} onClick={()=>filterCateogy(item.Playlist_ID, item.Include_Video_ID)}>{item.Playlist_Title}</span>
                        )
                      })
                    }
                  </div>
   
              </div>
            </Col>
          </Row>
        </Container>

        { video_items && 
          <VideosList data = {video_items} video_list={video_list} include_videos={include_videos} filterCat={filterCat} GQLModules={props.GQLModules} />
        }
      </div>
    </React.Fragment>
  )
}

export default Videos